<!-- ReviewDialog.vue -->
<template>
  <el-dialog
    :title="lang == 'ZH' ? '回顾' : 'Review'"
    :visible="visible"
    class="dialog"
    append-to-body
    width="90%"
    :show-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClose"
  >
    <div class="form-container">
      <el-form :model="reviewForm" :rules="reviewRules" ref="reviewForm">
        <el-form-item
          v-for="(question, index) in filteredQuestionList"
          :key="index"
        >
          <!-- 仅显示非multiobj的子问题，和那些被选中的普通问题的子问题，这些子问题的is_subquestion被设置成了false。 -->
          <div>
            {{ lang == "ZH" ? "问题" : "Question" }} {{ question.index }}:
            <span v-if="question.is_hidden == true" style="color:red;"
              >(Hidden)</span
            >
            {{ lang == "ZH" ? question.question_zh : question.question_en }}
          </div>
          <!-- 1.multiple object显示表格-->
          <div v-if="question.type == 'multiple_object'">
            <el-table :data="question.answers" border>
              <el-table-column
                v-for="(column, columnIndex) in question.choices['Yes']"
                :key="columnIndex"
                :label="
                  questionList[column - 1].index +
                    ': ' +
                    (questionList[column - 1].is_hidden ? '(Hidden)' : '') +
                    multiQnsInReview(column)
                "
                width="220"
                style="word-break:normal"
              >
                <template slot-scope="scope">
                  <template
                    v-if="questionList[column - 1].type == 'single_choice'"
                  >
                    <el-select
                      v-model="question.answers[scope.$index][columnIndex]"
                      @change="change"
                      :placeholder="lang == 'ZH' ? '请选择' : 'Please select'"
                    >
                      <el-option
                        v-for="(choice, index) in getChoices(
                          questionList[column - 1]
                        )"
                        :key="index"
                        :label="choice"
                        :value="choice"
                      >
                      </el-option>
                    </el-select>
                  </template>
                  <template
                    v-if="getChoices(questionList[column - 1]).length == 0"
                  >
                    <el-input
                      v-model="question.answers[scope.$index][columnIndex]"
                    />
                  </template>
                </template>
              </el-table-column>

              <el-table-column
                :label="lang == 'ZH' ? '操作' : 'Operation'"
                style="overflow-wrap: break-word;"
                width="100%"
              >
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    @click="handleDelete(question, scope.$index)"
                    >{{ lang == "ZH" ? "删除" : "Delete" }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-button type="primary" @click="handleAdd(question)">{{
              lang == "ZH" ? "新增一行" : "Add a row"
            }}</el-button>
          </div>

          <!-- 多选题 -->
          <el-select
            v-if="
              getChoices(question).length > 0 &&
                question.type == 'multiple_choice' &&
                question.type != 'multiple_object'
            "
            :placeholder="lang == 'ZH' ? '请选择' : 'Please select'"
            v-model="question.answers"
            @change="change(question)"
            multiple
          >
            <el-option
              v-for="(choice, index) in getChoices(question)"
              :key="index"
              :label="choice"
              :value="choice"
            >
            </el-option>
          </el-select>

          <!-- 单选题 -->
          <el-select
            v-if="
              getChoices(question).length > 0 &&
                question.type != 'multiple_choice' &&
                question.type != 'multiple_object'
            "
            :placeholder="lang == 'ZH' ? '请选择' : 'Please select'"
            v-model="question.answers[0]"
            @change="change(question)"
          >
            <el-option
              v-for="(choice, index) in getChoices(question)"
              :key="index"
              :label="choice"
              :value="choice"
            >
            </el-option>
          </el-select>

          <!-- 填空题 -->
          <el-input
            v-if="getChoices(question).length == 0"
            v-model="question.answers[0]"
            :disabled="question.type == 'address'"
          />
        </el-form-item>

        <el-form-item
          :label="
            lang == 'ZH' ? '估值报告货币单位' : 'Valuation Report Currency'
          "
          prop="curr"
        >
          <el-select
            v-model="reviewForm.curr"
            :placeholder="
              lang == 'ZH' ? '请选择货币单位' : 'Please select currency'
            "
          >
            <el-option
              v-for="item in curr_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-button
          type="primary"
          @click="submitToServer"
          :loading="submitBtnLoading"
          :disabled="submitBtnLoading"
          >{{ lang == "ZH" ? "提交" : "Submit" }}</el-button
        >
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ReviewDialog",
  props: {
    user_inputs: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
      default: "EN",
    },
    report_currency: {
      type: String,
      required: true,
      default: "USD",
    },
    currency: {
      type: String,
      required: true,
      default: "USD",
    },
    answer_address: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      user_questions: {},
      model_questions: [],
      patentQnsList: [
        "patent_title",
        "patent_number",
        "patent_is_owned_exclusively",
        "patent_year_granted",
        "patent_year_applied",
        "patent_is_high_value",
        "patent_forward_citations",
        "patent_backward_citations",
        "patent_family_forward_citations",
        "patent_non_patent_backward_citations",
        "patent_life_cycle",
        "patent_assignees",
        "patent_family_size",
        "patent_ipcs",
        "patent_has_filed_ipc",
        "patent_max_claims",
        "patent_applicant_count",
        "patent_applicant_address",
      ],
      visible: true,
      multi_obj_subquestions: [],
      questionList: [],
      reviewForm: {
        curr: "",
      },
      reviewRules: {
        curr: [
          {
            required: true,
            message:
              this.lang == "ZH" ? "请选择一个选项" : "Please select an option",
            trigger: "change",
          },
        ],
      },
      curr_options: [
        {
          value: "GBP",
          label: this.lang == "ZH" ? "英镑" : "GBP",
        },
        {
          value: "USD",
          label: this.lang == "ZH" ? "美元" : "USD",
        },
        {
          value: "CNY",
          label: this.lang == "ZH" ? "人民币" : "CNY",
        },
        {
          value: "EUR",
          label: this.lang == "ZH" ? "欧元" : "EUR",
        },
      ],
      submitBtnLoading: false,
    };
  },
  methods: {
    convertStringToNumber(str) {
      const numberMap = {
        hundred: 100,
        thousand: 1000,
        million: 1000000,
        billion: 1000000000,
        trillion: 1000000000000,
        hundreds: 100,
        thousands: 1000,
        millions: 1000000,
        billions: 1000000000,
        trillions: 1000000000000,
        百: 100,
        千: 1000,
        万: 10000,
        十万: 100000,
        百万: 1000000,
        千万: 10000000,
        亿: 100000000,
        十亿: 1000000000,
        百亿: 10000000000,
        千亿: 100000000000,
        万亿: 1000000000000,
      };

      // 有事会出现answer有值但是是undefined的情况，把这种值暂时转成0
      if (str === null || str === undefined) {
        return 0;
      }

      const cleanedStr = str.replace(/[^a-zA-Z\d\u4e00-\u9fa5.-]+/g, ""); // 在字符集中添加负号
      const regex = /(-?\d+(\.\d+)?)([a-zA-Z\u4e00-\u9fa5]+)?/i; // 在数字匹配部分添加可选的负号
      const match = cleanedStr.match(regex);

      if (match) {
        const numberPart = parseFloat(match[1]);
        const unit = match[3] ? match[3].toLowerCase() : undefined;

        if (unit && numberMap[unit]) {
          return numberPart * numberMap[unit];
        } else {
          return numberPart;
        }
      }
      return NaN; // 返回 NaN 表示无法解析
    },
    handleClose() {
      this.visible = false;
      // 通知父组件销毁当前组件
      this.$emit("close");
    },
    multiQnsInReview(column) {
      return this.lang == "ZH"
        ? this.questionList[column - 1].question_zh
        : this.questionList[column - 1].question_en;
    },
    getChoices(question) {
      return Object.keys(
        this.lang === "ZH" ? question.choices_zh : question.choices
      );
    },
    handleAdd(question) {
      let current_mul_obj_subs = question.choices["Yes"];
      let all_empty_array = [];
      for (let i = 0; i < current_mul_obj_subs.length; i++) {
        all_empty_array.push("");
      }
      question.answers.push(all_empty_array);
    },
    handleDelete(question, index) {
      question.answers.splice(index, 1);
    },

    updateChoicesZh(choices, choices_zh) {
      // gpt写的:
      // 获取英文选项的键名数组和中文选项的键名数组
      const keysEn = Object.keys(choices);
      const keysZh = Object.keys(choices_zh);

      // 遍历英文选项，更新中文选项的值
      keysEn.forEach((keyEn, index) => {
        const keyZh = keysZh[index]; // 根据索引找到对应的中文键名
        if (keyZh) {
          choices_zh[keyZh] = choices[keyEn]; // 更新中文选项的值为对应英文选项的值
        }
      });
    },
    change(question) {
      if (this.lang == "ZH") {
        this.updateChoicesZh(question.choices, question.choices_zh);
      }

      if (typeof question == "object") {
        const choicesKey = this.lang === "ZH" ? "choices_zh" : "choices";

        Object.keys(question[choicesKey]).forEach((option) => {
          if (
            question[choicesKey][option] != null &&
            !question.answers.includes(option)
          ) {
            question[choicesKey][option].forEach((index) => {
              this.questionList[parseInt(index) - 1]["is_subquestion"] = true;
              this.questionList[parseInt(index) - 1]["answers"] = [];
            });
          } else if (
            question[choicesKey][option] != null &&
            question.answers.includes(option)
          ) {
            question[choicesKey][option].forEach((index) => {
              this.questionList[parseInt(index) - 1]["is_subquestion"] = false;
            });
          }
        });
      }
    },

    async submitToServer() {
      // 验证表单
      try {
        await this.$refs.reviewForm.validate((valid) => {
          if (valid) {
            console.log(`已选择报告的货币单位：${this.reviewForm.curr}`);
          } else {
            console.log("没选货币");
            return;
          }
        });
      } catch (error) {
        console.log(error);
        return;
      }

      //将multiobj的问题答案填回给子问题
      for (let i = 0; i < this.questionList.length; i++) {
        // 首先找出mutiobj的问题
        if (this.questionList[i].type == "multiple_object") {
          // 然后遍历他的子问题
          for (let j = 0; j < this.questionList[i].choices["Yes"].length; j++) {
            let subQnIndex = this.questionList[i].choices["Yes"][j] - 1;
            // 把这个multiobj的子问题的答案首先清空
            this.questionList[subQnIndex].answers = [];
            // 然后把mulobj的答案填回去。一共有几个数组就填几个答案回去。k是第几个答案，j是第几个子问题
            for (let k = 0; k < this.questionList[i].answers.length; k++) {
              this.questionList[subQnIndex].answers.push(
                this.questionList[i].answers[k][j]
              );
            }
          }
        }
      }

      //验证必填题目是否都填了
      let not_answered = [];
      // 这个循环用于判断非multiobj的
      for (let i = 0; i < this.filteredQuestionList.length; i++) {
        if (
          this.filteredQuestionList[i]["is_required"] == true &&
          this.filteredQuestionList[i]["type"] !== "multiple_object" &&
          (this.filteredQuestionList[i]["answers"].length < 1 ||
            this.filteredQuestionList[i]["answers"][0] == "Not Answered" ||
            this.filteredQuestionList[i]["answers"][0] == null ||
            this.filteredQuestionList[i]["answers"][0] === "")
        ) {
          // 这里的index是题号，从1开始的
          not_answered.push(this.filteredQuestionList[i].index);
        }
      }
      // 这个循环用于判断multiojb的子问题
      for (let i = 0; i < this.multi_obj_subquestions.length; i++) {
        let qn = this.questionList[this.multi_obj_subquestions[i] - 1];
        // 如果是专利的子问题跳出循环 不用判断
        if (this.patentQnsList.includes(qn.variable_name)) {
          continue;
        }
        for (let j = 0; j < qn.answers.length; j++) {
          //multiobj的子问题的answers是一个数组。
          let ans = qn.answers[j];
          if (
            this.questionList[this.multi_obj_subquestions[i] - 1].is_required ==
              true &&
            (ans.length < 1 ||
              ans == "Not Answered" ||
              ans == null ||
              ans === "")
          ) {
            not_answered.push(qn.index);
          }
        }
      }
      if (not_answered.length > 0) {
        this.$message.error(
          `${this.lang == "ZH" ? "问题" : "Question"} ` +
            not_answered.toString() +
            `${this.lang == "ZH" ? " 未回答!" : " not answered!"} `
        );
        return;
      }

      this.submitBtnLoading = true;

      //把用户回答的东西转化成模型需要的东西
      // 深拷贝questionList 到model_questions
      this.model_questions = JSON.parse(JSON.stringify(this.questionList));
      for (let i = 0; i < this.model_questions.length; i++) {
        let model_answer = [];
        // 预设多选题不会出现在multipleObj的子问题中
        if (this.model_questions[i]["type"] == "multiple_choice") {
          let ans = this.model_questions[i]["answers"];
          if (ans == "Not Answered" || ans == null || ans == "" || ans == []) {
            model_answer.push("#empty");
            continue;
          }
          // ans是数组
          let answers = ans;
          for (let m = 0; m < answers.length; m++) {
            let answer = answers[m];
            if (this.model_questions[i]["export_type"] == "index_0") {
              for (
                let k = 0;
                k < Object.keys(this.model_questions[i]["choices"]).length;
                k++
              ) {
                if (
                  answer.trim() ==
                  this.getChoices(this.model_questions[i])[k].trim()
                ) {
                  model_answer.push(k + "");
                }
              }
            }
            if (this.model_questions[i]["export_type"] == "index_1") {
              for (
                let k = 0;
                k < Object.keys(this.model_questions[i]["choices"]).length;
                k++
              ) {
                if (
                  answer.trim() ==
                  this.getChoices(this.model_questions[i])[k].trim()
                ) {
                  model_answer.push(k + 1 + "");
                }
              }
            }
            if (this.model_questions[i]["export_type"] == "customize") {
              Object.keys(
                this.model_questions[i][
                  `${this.lang == "ZH" ? "custom_zh" : "custom"}`
                ]["customize_values"]
              ).forEach((key) => {
                if (answer && answer.trim() == key.trim()) {
                  model_answer.push(
                    this.model_questions[i][
                      `${this.lang == "ZH" ? "custom_zh" : "custom"}`
                    ]["customize_values"][key]
                  );
                }
              });
            }
          }

          if (model_answer.length != 0) {
            this.model_questions[i]["answers"] = model_answer;
            continue;
          }
        } else {
          //某些问题需要多个答案 mutiple object 专利有可能会添加多个，所以那些有关专利的问题会被回答多次就会有多个答案
          for (let j = 0; j < this.model_questions[i]["answers"].length; j++) {
            let ans = this.model_questions[i]["answers"][j];
            if (
              ans == "Not Answered" ||
              ans == null ||
              ans == "" ||
              ans == []
            ) {
              model_answer.push("#empty");
              continue;
            }

            // 如果是float类型进行转换 要在检查货币单位之前。
            if (this.model_questions[i]["type"] == "float") {
              if (typeof ans == "number") {
                model_answer.push(ans);
              } else {
                let convertRes = this.convertStringToNumber(ans);
                if (Number.isNaN(convertRes)) {
                  if (this.lang == "ZH") {
                    this.$message.error(
                      `问题${i + 1}的答案似乎不是数字，请检查并重新回答。`
                    );
                  } else {
                    this.$message.error(
                      `Question ${i +
                        1} does not appear to be a number. Please check and answer again.`
                    );
                  }

                  this.submitBtnLoading = false;
                  return;
                } else {
                  ans = convertRes;
                  model_answer.push(convertRes);
                }
              }
            }

            // unify currency
            if (i > 0) {
              if (
                (this.model_questions[i - 1]["answers"][j] == "¥" ||
                  this.model_questions[i - 1]["answers"][j] == "人民币") &&
                this.currency != "CNY"
              ) {
                model_answer = [];
                model_answer.push(
                  `Get.currency(${ans}, CNY, ${this.currency})`
                );
              } else if (
                (this.model_questions[i - 1]["answers"][j] == "€" ||
                  this.model_questions[i - 1]["answers"][j] == "欧元") &&
                this.currency != "EUR"
              ) {
                model_answer = [];
                model_answer.push(
                  `Get.currency(${ans}, EUR, ${this.currency})`
                );
              } else if (
                (this.model_questions[i - 1]["answers"][j] == "$" ||
                  this.model_questions[i - 1]["answers"][j] == "美元") &&
                this.currency != "USD"
              ) {
                model_answer = [];
                model_answer.push(
                  `Get.currency(${ans}, USD, ${this.currency})`
                );
              } else if (
                (this.model_questions[i - 1]["answers"][j] == "£" ||
                  this.model_questions[i - 1]["answers"][j] == "英镑") &&
                this.currency != "GBP"
              ) {
                model_answer = [];
                model_answer.push(
                  `Get.currency(${ans}, GBP, ${this.currency})`
                );
              }
            }
            // transform answers according to export type
            if (
              this.model_questions[i]["export_type"] == "1/0" ||
              this.model_questions[i]["export_type"] == "number"
            ) {
              if (ans == "yes" || ans == "Yes" || ans == "YES" || ans == "是") {
                model_answer.push("1");
              } else if (
                ans == "no" ||
                ans == "No" ||
                ans == "NO" ||
                ans == "否"
              ) {
                model_answer.push("0");
              }
            }
            if (this.model_questions[i]["export_type"] == "index_0") {
              for (
                let k = 0;
                k < Object.keys(this.model_questions[i]["choices"]).length;
                k++
              ) {
                if (
                  ans &&
                  ans.trim() ==
                    this.getChoices(this.model_questions[i])[k].trim()
                ) {
                  model_answer.push(k + "");
                  break;
                }
              }
            }
            if (this.model_questions[i]["export_type"] == "index_1") {
              for (
                let k = 0;
                k < Object.keys(this.model_questions[i]["choices"]).length;
                k++
              ) {
                if (
                  ans &&
                  ans.trim() ==
                    this.getChoices(this.model_questions[i])[k].trim()
                ) {
                  model_answer.push(k + 1 + "");
                  break;
                }
              }
            }
            if (this.model_questions[i]["export_type"] == "customize") {
              Object.keys(
                this.model_questions[i][
                  `${this.lang == "ZH" ? "custom_zh" : "custom"}`
                ]["customize_values"]
              ).forEach((key) => {
                if (ans && ans.trim() == key.trim()) {
                  model_answer.push(
                    this.model_questions[i][
                      `${this.lang == "ZH" ? "custom_zh" : "custom"}`
                    ]["customize_values"][key]
                  );
                }
              });
            }
            // 地址题的答案从父组件直接传入不允许修改
            if (this.model_questions[i]["type"] == "address") {
              model_answer = this.answer_address;
            }
          }

          if (model_answer.length != 0) {
            this.model_questions[i]["answers"] = model_answer;
          }
        }
      }
      // transform answers  这里就是把答案从数组变成字符串
      Object.keys(this.model_questions).forEach((key) => {
        // transform only questions that are not sub questions of a multiple object question and not multiple choice and not address
        // 就是以上三种问题（1:multiple_object问题的子问题 2：multiple_object问题 3：address问题） 是不需要被转化成字符串的 这是后端的要求。 下面的if的3个条件就是在判断这三类问题。 只有判断完了都不是以上3类问题 if里面的内容就是才开始转化他们
        if (
          this.multi_obj_subquestions.indexOf(parseInt(key) + 1) == -1 &&
          this.model_questions[key]["type"] != "multiple_choice" &&
          this.model_questions[key]["type"] != "address"
        ) {
          // if answer empty, fill a "0" answer
          if (
            this.model_questions[key]["answers"] == null ||
            this.model_questions[key]["answers"].length == 0 ||
            this.model_questions[key]["answers"] == []
          ) {
            this.model_questions[key]["answers"] = "#empty";
          } else {
            // transform answer array [1,2,3] into string "1 2 3"
            let a = "";
            this.model_questions[key]["answers"].forEach((answer) => {
              a = a + answer + " ";
            });
            a = a.slice(0, -1); // remove the last space
            this.model_questions[key]["answers"] = a;
          }
        } else {
          // for empty subquestions/multiple choice/address, fill a "0" answer
          //其实就是不管这3类问题， 但是如果用户没回答，那这里我们给他赋值成空数组
          if (this.model_questions[key]["answers"].length == 0) {
            this.model_questions[key]["answers"] = [];
          }
        }
      });

      // 设置用户输入
      this.user_questions.company_information = {};
      for (let i = 0; i < this.questionList.length; i++) {
        const index = this.questionList[i].index;
        this.user_questions.company_information[index] = this.questionList[i];
      }

      // 把用户输入暴露给外面进行提交
      this.$emit(
        "submit",
        this.user_questions,
        this.model_questions,
        this.reviewForm.curr
      );

      this.submitBtnLoading = false;
    },
  },
  created() {
    //将传递过来的user_inputs对象处理好，填入questionLish数组
    Object.keys(this.user_inputs).forEach((key) => {
      this.questionList.push(this.user_inputs[key]);
    });
    console.log("-----------------------");
    console.log("questionList:");
    console.log(this.questionList);

    // 把传递过来的报告货币单位填入。
    this.reviewForm.curr = this.report_currency;

    // 处理multiple_object子问题
    this.questionList.forEach((qn) => {
      if (qn.type == "multiple_object") {
        // 把multiobj的子问题记录下来，存入multi_obj_subquestions里面。
        console.log("multiple_obj:");
        console.log(qn);
        this.multi_obj_subquestions.push(...qn.choices["Yes"]);
        console.log("multi_obj_subquestions:");
        console.log(this.multi_obj_subquestions);

        // 把multiobj的子问题的答案回填给multiobj本身，用于review表格的显示
        let current_mul_obj_subs = qn.choices["Yes"]; // [16,17,18]
        qn.answers = [];
        //这个for循环用于把answers变成 [[],[],[]]
        for (
          let j = 0;
          j < this.questionList[current_mul_obj_subs[0] - 1].answers.length;
          j++
        ) {
          qn.answers.push([]);
        }
        // answers 要变成 [[16[0],17[0],18[0]],[16[1],17[1],18[1]],[16[2],17[2],18[2]]]
        for (let j = 0; j < current_mul_obj_subs.length; j++) {
          let current_sub = current_mul_obj_subs[j]; // 第一次循环是16
          for (let k = 0; k < qn.answers.length; k++) {
            if (
              typeof this.questionList[current_sub - 1].answers[k] == "boolean"
            ) {
              let ans = this.questionList[current_sub - 1].answers[k];
              ans == true
                ? qn.answers[k].push(this.getChoices(qn)[0])
                : qn.answers[k].push(this.getChoices(qn)[1]);
            } else {
              qn.answers[k].push(this.questionList[current_sub - 1].answers[k]); // 这个循环完会变成[[16[0]],[16[1]],[16[2]]]
            }
          }
        }
      }
    });
  },
  computed: {
    filteredQuestionList() {
      return this.questionList.filter(
        (question, index) =>
          this.multi_obj_subquestions.indexOf(index + 1) === -1 &&
          !question.is_subquestion
      );
    },
  },
};
</script>

<style scoped>
.dialog {
  text-align: left;
  font-size: large;
}
.form-container {
  height: 60vh;
  overflow-y: auto;
}
::v-deep .el-input {
  width: 198px;
}
</style>
